import React from "react";
import Experience from "../Items/Experience";

const experiencesData = [
  {
    id: 1,
    year: "2020 - Present",
    degree: "Development Team Lead - Fitness SaaS Platform",
    content:
      [
        "Implementation expert working on critical areas of backend code for ASP.NET Core microservices SaaS platform, optimising performance hotspots, building library functions for the team to use, and more",
        "Advising other teams on feature/API design and implementation",
        "Teaching and driving adoption of development best practices",
        "Mentoring, managing, and recruiting other developers"
      ]
  },
  {
    id: 2,
    year: "2018 - 2020",
    degree: "Senior Developer - Fitness SaaS Platform",
    content:
      [
        "Developed backend for greenfield SaaS products in ASP.NET Core running on Azure/Kubernetes microservices architecture",
        "Took a lead developer role within agile team, guiding and mentoring other developers",
        "Led design and implementation of some crucial parts such as inter-service message queues (RabbitMQ) and reporting stack (Snowflake/dbt)",
        "Maintained the full stack of an older SaaS product in ASP.NET MVC and TypeScript, Bootstrap, KnockoutJS",
        "Set up CI pipelines and processes in Azure DevOps to automate deployment",
        "Managed Azure infrastructure and reduced cloud costs of one product by 30%"
      ]
  },
  {
    id: 3,
    year: "2016 - 2018",
    degree: "Software Team Lead - Scientific Desktop App",
    content:
      [
        "Led development team working on C# scientific desktop application interfacing with real-time hardware",
        "Organised workloads and managed team, processes, and tools"
      ]
  },
  {
    id: 4,
    year: "2009 - 2016",
    degree: "Software Engineer - Scientific Desktop App",
    content:
      [
        "Maintained and developed mature C# scientific desktop application interfacing with earthquake simulation hardware",
        "Developed real-time embedded Digital Signal Processing code in C",
        "Modernised development processes with bug and feature tracking, continuous integration, migrating from Subversion to Git",
        "Provided third-line support directly to customers"  
      ],
  },
  {
    id: 5,
    year: "2006 - 2009",
    degree: "BSc Computer Science, 1st Class Honours",
    content:
      [
        "Studied various subjects: algorithms, data networking, databases, signal processing, 3D graphics, operating system theory, and more",
        "Won a scholarship prize"
      ],
  },
];

function Experiences() {
  return (
    <div className="timeline">
      {experiencesData.map((experience) => (
        <Experience experience={experience} key={experience.id} />
      ))}
      <span className="timeline-line"></span>
    </div>
  );
}

export default Experiences;
