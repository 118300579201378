import React from "react";
import { FaReact } from "react-icons/fa";
import Service from "../Items/Service";

const servicesData = [
  {
    id: 1,
    name: "C#/ASP.NET",
    content: "Expert level skill, experienced in .NET 5 (formerly Core) and Framework.",
    icon: "icon-globe",
  },
  {
    id: 2,
    name: "Entity Framework",
    content: "Highly adept at writing clear and performant LINQ database queries.",
    icon: "icon-layers",
  },
  {
    id: 3,
    name: "Git",
    content: "Git wizard. The guy you call when you've made a mess of your commit tree.",
    icon: "icon-magic-wand",
  },
  {
    id: 4,
    name: "Microservices Architecture",
    content: "Experience designing microservices, interservice messaging (RabbitMQ), and other backend systems.",
    icon: "icon-organization",
  },
  {
    id: 5,
    name: "Azure Cloud & DevOps",
    content: "Comfortable provisioning and configuring services and CI pipelines.",
    icon: "icon-rocket",
  },
  {
    id: 6,
    name: "API Design",
    content: "Able to design (and document) sensible, easy-to-consume, RESTful APIs.",
    icon: "icon-notebook",
  },
  {
    id: 7,
    name: "Problem Solving",
    content: "Anaytical mind enhanced with years of heuristics, and knowing what to type into Google.",
    icon: "icon-compass",
  },
 
  {
    id: 8,
    name: "React",
    content: <span>Still learning - enjoyed using it in a <a href="https://elite.kamd.me.uk">side project</a> and would be excited to dig in more at work.</span>,
    icon: <FaReact className="icon-simple"/>,
  },
  {
    id: 9,
    name: "Fast Learning",
    content: "Proven to learn new concepts quickly. I have the intelligence and experience to get to grips with what's needed.",
    icon: "icon-graduation",
  },
];

function Services() {
  return (
    <div className="row -mt-20">
      {servicesData.map((service) => (
        <div className="col-md-4 col-sm-6 mt-20" key={service.id}>
          <Service service={service} />
        </div>
      ))}
    </div>
  );
}

export default Services;
