import React from "react";
import TrackVisibility from "react-on-screen";
import Funfact from "../Items/Funfact";

const funfactData = [
  {
    id: 1,
    title: "Years developing software",
    count: YearsDeveloping(),
    icon: "icon-calendar",
  },
  {
    id: 3,
    title: "Microservices worked on",
    count: 27,
    icon: "icon-organization",
  },
  {
    id: 2,
    title: "Cups of tea (estimated)",
    count: CupsOfTea(),
    icon: "icon-cup",
  },
  {
    id: 4,
    title: "Years unable to quit Vim",
    count: YearsDeveloping() - 3,
    icon: "icon-note",
  },
];

function YearsDeveloping() {
  const msDeveloping = MillisecondsDeveloping();
  const msInYear = 1000 * 60 * 60 * 24 * 365;
  const yearsDeveloping = Math.floor(msDeveloping / msInYear);
  return yearsDeveloping;
}

function MillisecondsDeveloping(){
  return new Date() - new Date('2009-05-01');
}

function CupsOfTea(){
  const msDeveloping = MillisecondsDeveloping();
  const msInDay = 1000 * 60 * 60 * 24;
  const cupsOfTeaPerWorkingDay = 3;
  const avgWorkingDaysPerWeek = 4.5 / 7.0;
  const estimatedCupsOfTea = Math.floor((msDeveloping / msInDay) * avgWorkingDaysPerWeek * cupsOfTeaPerWorkingDay);
  return estimatedCupsOfTea;
}

function Funfacts() {
  return (
    <section className="shadow-dark color-white background parallax padding-50">
      <div className="row relative z-1 -mt-50">
        {funfactData.map((funfact) => (
          <div className="col-md-3 col-sm-6 mt-50" key={funfact.id}>
            <TrackVisibility once>
              <Funfact funfact={funfact} />
            </TrackVisibility>
          </div>
        ))}
      </div>
      <div className="overlay"></div>
    </section>
  );
}

export default Funfacts;
